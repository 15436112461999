import React from "react";
import BlogList from "./blog-list";
import CallToActionV1 from "../section-components/call-to-action-v1";
import Footer from "../global-components/footer";

const BlogLeftSidebar = () => {
  return (
    <div>
      <BlogList />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default BlogLeftSidebar;
