import React, { Component } from "react";

class TestimonialV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__testimonial-area ltn__testimonial-4 pt-115 pb-100 plr--9">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Testimonial
                </h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__testimonial-slider-4 ltn__testimonial-slider-4-active slick-arrow-1">
                <div className="ltn__testimonial-item-5">
                  <div className="ltn__quote-icon">
                    <i className="far fa-comments" />
                  </div>
                  <div className="ltn__testimonial-image">
                    <img
                      src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                      alt="quote"
                    />
                  </div>
                  <div className="ltn__testimonial-info">
                    <p>
                      Andrew's knowledge of the market, attention to detail, and
                      personalized service made the entire process smooth and
                      stress-free. Not only was he able to find us the perfect
                      home, but he also navigated the negotiations and closing
                      process with expertise and efficiency. We couldn't be
                      happier with our new home, and we have Andrew to thank for
                      making it possible!
                    </p>
                    <h4>Jake Armijo</h4>
                    <h6>Home Buyer</h6>
                  </div>
                </div>
                <div className="ltn__testimonial-item-5">
                  <div className="ltn__quote-icon">
                    <i className="far fa-comments" />
                  </div>
                  <div className="ltn__testimonial-image">
                    <img
                      src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                      alt="quote"
                    />
                  </div>
                  <div className="ltn__testimonial-info">
                    <p>
                      Thank you for your excellent work in helping us find and
                      purchase our new home. Your professionalism and expertise
                      made the process smooth and stress-free. We are very happy
                      with our new home and grateful for your help in making it
                      possible.{" "}
                    </p>
                    <h4>Kelian Anderson</h4>
                    <h6>Home Buyer</h6>
                  </div>
                </div>
                <div className="ltn__testimonial-item-5">
                  <div className="ltn__quote-icon">
                    <i className="far fa-comments" />
                  </div>
                  <div className="ltn__testimonial-image">
                    <img
                      src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                      alt="quote"
                    />
                  </div>
                  <div className="ltn__testimonial-info">
                    <p>
                      We are sincerely gratitude for Andrew’s hard work and
                      dedication in selling our home. We were on a tight
                      timeline and he made it happen. He sold our home quickly
                      and for a great price. We couldn't be happier with the
                      outcome and have Andrew to thank for it.{" "}
                    </p>
                    <h4>The Joseph's</h4>
                    <h6>Home Sellers</h6>
                  </div>
                </div>
                <div className="ltn__testimonial-item-5">
                  <div className="ltn__quote-icon">
                    <i className="far fa-comments" />
                  </div>
                  <div className="ltn__testimonial-image">
                    <img
                      src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                      alt="quote"
                    />
                  </div>
                  <div className="ltn__testimonial-info">
                    <p>
                      Working side by side with Andrew has been a fantastic
                      experience and I have learned so much from him. It was a
                      treat from the beginning, and it paid off in the end with
                      a smooth and successful sale. I couldn't have asked for a
                      better coworker to work with on this project.
                    </p>
                    <h4>Coworker</h4>
                    <h6>Real Estate Agent</h6>
                  </div>
                </div>
              </div>
              <ul className="ltn__testimonial-quote-menu d-none d-lg-block">
                <li>
                  <img
                    src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                    alt="Quote-1"
                  />
                </li>
                <li>
                  <img
                    src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                    alt="Quote-2"
                  />
                </li>
                <li>
                  <img
                    src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                    alt="Quote-3"
                  />
                </li>
                <li>
                  <img
                    src={publicUrl + "assets/img/head-shots/selfie-2.jpg"}
                    alt="Quote-4"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialV3;
