import React, { Component } from "react";

class Map extends Component {
  render() {
    return (
      <div className="google-map mb-120">
        {/* <iframe
          src="https://goo.gl/maps/TizHh8djvzWbfUpV9"
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
          title="Map-Iframe"
        /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127140.4409250876!2d-86.82061951171598!3d36.17823581036908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8864ec3213eb903d%3A0x7d3fb9d0a1e9daa0!2sNashville%2C%20TN!5e0!3m2!1sen!2sus!4v1670957889138!5m2!1sen!2sus"
          width="100%"
          height="100%"
          allowFullScreen
          aria-hidden="false"
          loading="lazy"
          tabIndex={0}
          referrerPolicy="no-referrer-when-downgrade"
          title="Nashville-Map-Iframe"
        ></iframe>
      </div>
    );
  }
}

export default Map;
