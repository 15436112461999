import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";

class BlogDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__blog-details-wrap">
                <div className="ltn__page-details-inner ltn__blog-details-inner">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-category">
                        <li>
                          <a
                            href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Nashville Real Estate
                          </a>
                        </li>
                      </li>
                    </ul>
                  </div>
                  <h2 className="ltn__blog-title">Why move to Nashville?</h2>
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author go-top">
                        <Link to="/about">
                          <img
                            src={
                              publicUrl + "assets/img/head-shots/selfie-1.jpg"
                            }
                            alt="Author"
                          />
                          By: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-date">
                        <i className="far fa-calendar-alt" />
                        Dec 9, 2022
                      </li>
                      {/* <li>
							<Link to="#"><i className="far fa-comments" />35 Comments</Link>
						</li> */}
                    </ul>
                  </div>
                  <p>
                    Nashville, Tennessee is a vibrant and exciting city with a
                    lot to offer for people looking to move. Whether you are a
                    young professional looking for a great job opportunity, a
                    family looking for a great place to raise your children, or
                    a retiree looking to enjoy your golden years, Nashville has
                    something for everyone.
                  </p>
                  <img
                    src={
                      publicUrl +
                      "assets/img/blog/city/nashville-city-fireworks.jpg"
                    }
                    alt="Nashville-City-Fireworks-NYE"
                  />
                  <h2>Job Market</h2>
                  <p>
                    One of the top reasons to move to Nashville is the job
                    market. The city has a strong and growing economy, with
                    plenty of job opportunities in a variety of industries,
                    including healthcare, technology, and entertainment. The
                    cost of living in Nashville is also relatively low, compared
                    to other major cities, which makes it an affordable place to
                    live and work.
                  </p>
                  <h2>Quality of life</h2>
                  <p>
                    Another great reason to move to Nashville is the quality of
                    life. The city has a friendly and welcoming atmosphere, with
                    thriving art and music scene. There are plenty of great
                    restaurants, bars, and cafes to enjoy, as well as various
                    outdoor activities, such as hiking, biking, and camping.
                    Nashville also has a great public school system and a number
                    of top-rated universities, making it a great place to raise
                    a family.
                  </p>
                  <h2>Retirement</h2>
                  <p>
                    Lastly, Nashville is a great place to retire. The city has a
                    warm and mild climate, with plenty of senior-friendly
                    amenities, such as{" "}
                    <a
                      href="https://www.tripadvisor.ca/Attractions-g55229-Activities-c61-t60-Nashville_Davidson_County_Tennessee.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      golf courses
                    </a>
                    , community centers, and fitness facilities. There are also
                    a number of affordable housing options for seniors, as well
                    as access to top-quality healthcare.
                  </p>
                  <h2>Fantastic Nashville</h2>
                  <p>
                    In short, Nashville is a fantastic place to live, work, and
                    retire. If you are considering a move, Nashville should
                    definitely be on your list of potential destinations.
                  </p>
                  <h2>Recap</h2>
                  <div className="list-item-with-icon-2">
                    <ul>
                      <li>Job Market</li>
                      <li>Quality</li>
                      <li>Retirement</li>
                      <li>Nashville is Fantastic</li>
                    </ul>
                  </div>
                  <blockquote>
                    <h6 className="ltn__secondary-color mt-0">
                      Ready to Move?
                    </h6>
                    Contact Andrew Phipps today for available real estate
                    options.
                  </blockquote>
                  <img
                    className="alignleft"
                    src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                    alt="Andrew Headshot"
                  />
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
					<h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur expedita velit laboriosam est sint laborum eos assumenda, quam voluptatem adipisci, reprehenderit ut nobis blanditiis perspiciatis!</p> */}
                  {/* <div className="row">
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/31.jpg"} alt="Image" />
						<label>Nashville on NYE</label>
						</div>
						<div className="col-lg-6">
						<img src={publicUrl+"assets/img/service/32.jpg"} alt="Image" />
						</div>
					</div> */}
                  <p>
                    As a top-rated real estate agent in Nashville, Tennessee I
                    have a passion for helping my clients buy and sell homes.
                    With years of experience in the industry, I have the
                    knowledge and expertise to guide you through every step of
                    the process, from finding the perfect property to
                    negotiating the best price.
                  </p>
                  <p>
                    I am committed to providing exceptional customer service and
                    ensuring that your real estate experience is smooth and
                    stress-free. I am always available to answer your questions
                    and provide expert advice, and I will work tirelessly to
                    help you achieve your real estate goals.
                  </p>
                  <p>
                    In addition to my extensive knowledge of the Nashville,
                    Tennessee real estate market, I am a skilled negotiator and
                    a great communicator. I will listen carefully to your needs
                    and wants and will use my expertise to help you find the
                    perfect home at the right price.
                  </p>
                  <p>
                    If you are looking for a dedicated, professional, and
                    experienced real estate agent, I would love the opportunity
                    to work with you. Don't hesitate to get in touch with me
                    today to schedule a consultation and start your real estate
                    journey.
                  </p>
                </div>
                {/* prev-next-btn */}
                <div className="ltn__prev-next-btn row mb-50">
                  <div className="blog-prev col-lg-6">
                    <Link to="/news-blog-details">
                      <h6>Prev Post</h6>
                    </Link>
                  </div>
                  <div className="blog-prev blog-next text-right text-end col-lg-6">
                    <Link to="/news-blog-details-1">
                      <h6>Next Post</h6>
                    </Link>
                  </div>
                </div>
                {/* blog-tags-social-media */}
                <div className="ltn__blog-tags-social-media mt-80 row">
                  <div className="ltn__tagcloud-widget col-lg-8 go-top">
                    <h4>Releted Tags</h4>
                    <ul>
                      <li>
                        <a
                          href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Home Buying
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Move to Nashville
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Real Estate Agent
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media text-right text-end col-lg-4">
                    <h4>Social Share</h4>
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/andrewjphipps"
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/Phippsy806"
                          title="Twitter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/in/andrew-phipps-6668131b5/"
                          title="LinkedIn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/phippsy_1989/"
                          title="Instagram"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <hr />
                {/* related-post */}
                <div className="related-post-area mb-50">
                  {/* <h4 className="title-2">Related Post</h4> */}
                  {/* <div className="row"> */}
                  {/* <div className="col-md-6"> */}
                  {/* Blog Item */}
                  {/* <div className="ltn__blog-item ltn__blog-item-6">
							<div className="ltn__blog-img">
							<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/blog-details/11.jpg"} alt="Image" /></Link>
							</div>
							<div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />June 22, 2020
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
								vector icons sense.</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div>
						</div>
						</div> */}
                  {/* <div className="col-md-6"> */}
                  {/* Blog Item */}
                  {/* <div className="ltn__blog-item ltn__blog-item-6"> */}
                  {/* <div className="ltn__blog-img">
							<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/blog-details/12.jpg"} alt="Image" /></Link>
							</div> */}
                  {/* <div className="ltn__blog-brief">
							<div className="ltn__blog-meta">
								<ul>
								<li className="ltn__blog-date ltn__secondary-color">
									<i className="far fa-calendar-alt" />June 22, 2020
								</li>
								</ul>
							</div>
							<h3 className="ltn__blog-title"><Link to="/blog-details">A series of iOS 7 inspire
								vector icons sense.</Link></h3>
							<p>Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
							</div> */}
                  {/* </div>
						</div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }
}

export default BlogDetails;
