import React, { Component } from "react";

class CallToActonV1 extends Component {
  render() {
    return (
      <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                <div className="coll-to-info text-color-white">
                  <h1>Let's Find Your Dream Home</h1>
                  <p>We can help you navigate the market.</p>
                </div>
                <div className="btn-wrapper go-top">
                  <li className="btn btn-effect-3 btn-white">
                    <a
                      href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Explore Properties <i className="icon-next" />
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToActonV1;
