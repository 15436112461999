import React, { Component } from "react";

class Sponsor extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__brand-logo-area ltn__brand-logo-1 section-bg-1 pt-110 pb-110 plr--9 d-none---">
        <div className="container-fluid">
          <div className="row ltn__brand-logo-active">
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <a
                  href="https://www.tennesseetitle.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={
                      publicUrl +
                      "assets/img/brand-logo/Tennessee-Title-Service.jpg"
                    }
                    alt="Tennessee-Title"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <a
                  href="https://fbmortgageloans.com/lo/wwood/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={publicUrl + "assets/img/brand-logo/WestonWood.jpg"}
                    alt="Weston Wood - First Bank Mortgage"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <a
                  href="https://people.rate.com/david-zopf-146806"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={publicUrl + "assets/img/brand-logo/gr-2color.jpg"}
                    alt="David Zopf - Guaranteed Rate"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sponsor;
