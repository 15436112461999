import React from "react";
import NavbarV3 from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import TeamDetails from "./section-components/team-details";
import Testimonial from "./section-components/testimonial-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const About_v1 = () => {
  return (
    <div>
      <NavbarV3 />
      <PageHeader headertitle="About Me" />
      <TeamDetails />
      <Testimonial />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default About_v1;
