import React, { Component } from "react";
import { Link } from "react-router-dom";

class BlogSlider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let customClass = this.props.customClass ? this.props.customClass : "";
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : "";
    return (
      <div className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  News &amp; Blogs
                </h6>
                <h1 className="section-title">Latest News Feeds</h1>
              </div>
            </div>
          </div>
          <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/new-blog-details">
                    <img
                      src={
                        publicUrl +
                        "assets/img/blog/city/nashville-city-fireworks.jpg"
                      }
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/about">
                          <i className="far fa-user" />
                          by: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/news-blog-details">
                          <i className="fas fa-tags" />
                          Nashville
                        </Link>
                        <Link to="/news-blog-details">
                          <i className="fas fa-tags" />
                          Real Estate
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/news-blog-details">Why move to Nashville?</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Dec 9, 2022
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/news-blog-details">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/news-blog-details-1">
                    <img
                      src={
                        publicUrl +
                        "assets/img/blog/city/nashville-city-sunset.jpg"
                      }
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/about">
                          <i className="far fa-user" />
                          by: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/news-blog-details-1">
                          <i className="fas fa-tags" />
                          Nashville
                        </Link>
                        <Link to="/news-blog-details-1">
                          <i className="fas fa-tags" />
                          Weather
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/news-blog-details-1">Weather in Nashville</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Nov 26, 2022
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/news-blog-details-1">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/news-blog-details-2">
                    <img
                      src={
                        publicUrl +
                        "assets/img/blog/city/broadway-people-walking.jpg"
                      }
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/about">
                          <i className="far fa-user" />
                          by: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/news-blog-details-2">
                          <i className="fas fa-tags" />
                          Nashville
                        </Link>
                        <Link to="/news-blog-details-2">
                          <i className="fas fa-tags" />
                          Nightlife
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/news-blog-details-2">Nashville Nightlife</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Nov 28, 2022
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/news-blog-details-2">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item 4 */}
            {/* <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/news-blog-details">
                    <img src={publicUrl + "assets/img/blog/4.jpg"} alt="#" />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/about">
                          <i className="far fa-user" />
                          by: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/news-blog">
                          <i className="fas fa-tags" />
                          Interior
                        </Link>
                        <Link to="/news-blog">
                          <i className="fas fa-tags" />
                          Room
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/news-blog-details">
                      Renovating a Living Room? Experts Share Their Secrets
                    </Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          June 24, 2021
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/news-blog-details">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Blog Item 5 */}
            {/* <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/news-blog-details">
                    <img src={publicUrl + "assets/img/blog/5.jpg"} alt="#" />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/about">
                          <i className="far fa-user" />
                          by: Andrew Phipps
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/news-blog">
                          <i className="fas fa-tags" />
                          Interior
                        </Link>
                        <Link to="/news-blog">
                          <i className="fas fa-tags" />
                          Trends
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/news-blog-details">
                      7 home trends that will shape your house in 2021
                    </Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          June 24, 2021
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/news-blog-details">Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSlider;
