import React, { Component } from "react";
import ContactForm from "./contact-form";

class TeamDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__team-details-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                    alt="Team Member 1"
                  />
                </div>
                <h2>Andrew Phipps</h2>
                <h6 className="text-uppercase ltn__secondary-color">
                  Property Seller
                </h6>
                <div className="ltn__social-media-3">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/andrewjphipps"
                        title="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Phippsy806"
                        title="Twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/andrew-phipps-6668131b5/"
                        title="Linkedin"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="ltn__team-details-member-info-details">
                <p>
                  Andrew Phipps is a real estate agent in Nashville, Tennessee,
                  who brings a wealth of experience and expertise to the table.
                  With over 10 years of experience in hospitality, banking, and
                  technology, Andrew has honed his customer service,
                  negotiation, and communication skills to provide exceptional
                  service to his clients.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Positions:</strong> Property Seller
                        </li>
                        <li>
                          <strong>Location:</strong> Greater Nashville Area
                        </li>
                        <li>
                          <strong>Practice Area:</strong> Home Buying & Selling
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="ltn__team-details-member-about">
                      <ul>
                        <li>
                          <strong>Email:</strong>{" "}
                          andrew@andrewphippsrealestate.com
                        </li>
                        <li>
                          <strong>Phone:</strong> 217-549-6773
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr />
                <p>
                  In addition to his background in customer service, Andrew has
                  a unique understanding of operations and management, thanks to
                  his education in Sports Management and Sports Administration
                  from Eastern Illinois University. This allows him to
                  collaborate effectively with his clients to ensure a seamless
                  real estate transaction.
                </p>
                <hr />
                <p>
                  Andrew entered the real estate industry with the goal of being
                  his own boss and helping his clients save money. He has a
                  passion for improving the home buying process and sharing his
                  experiences to make the process smoother and more enjoyable
                  for his clients.When he is not working, Andrew enjoys spending
                  time with his wife, golfing, and coaching youth sports in
                  Nashville. He is excited to help his clients find the perfect
                  home in this vibrant and exciting city.
                </p>
                <hr />
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamDetails;
