import React from 'react';
import NavbarV3 from './global-components/navbar-v3';
import Banner from './section-components/banner-v3';
import SearchForm from './section-components/search-form';
import Features from './section-components/features-v1';
import Video from './section-components/video-v1';
import Testimonial from './section-components/testimonial-v3';
import Sponsor from './section-components/sponsor-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

import ReactGA from 'react-ga';

const TRACKING_ID = "G-V9KZRPJ2H2";
ReactGA.initialize(TRACKING_ID);

const Home_V3 = () => {
    return <div>
        <NavbarV3 />
        <Banner />
        <SearchForm />
        <Features customClass="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---"/>
        <Video />
        <Testimonial />
        <Sponsor />
        <BlogSlider sectionClass="pt-120"/>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V3

