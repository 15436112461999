import React, { Component } from "react";
import { Link } from "react-router-dom";

class SearchForm extends Component {
  render() {
    return (
      <div className="ltn__home-dealer-form-area mt--65 mt-120 pb-115---">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__home-dealer-form-tab">
                <div className="tab-content bg-white box-shadow-1 position-relative pb-10">
                  <div
                    className="tab-pane fade active show"
                    id="ltn__form_tab_1_1"
                  >
                    <div className="car-dealer-form-inner">
                      <form
                        action="#"
                        className="ltn__home-dealer-form-box row"
                      >
                        <div className="section-title-area ltn__section-title-2--- text-center">
                          <h1 className="section-title">
                            See What's Available
                          </h1>
                          <div className="ltn__home-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                            <div className="btn-wrapper text-center mt-0 go-top">
                              {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                              <a
                                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Explore Properties
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="ltn__form_tab_1_2">
                    <div className="car-dealer-form-inner">
                      <form
                        action="#"
                        className="ltn__home-dealer-form-box row"
                      >
                        <div className="ltn__home-dealer-form-item ltn__custom-icon---- ltn__icon-car---- col-lg-3 col-md-6">
                          <select className="nice-select">
                            <option>Choose Area</option>
                            <option>Nashville</option>
                            <option>Franklin</option>
                            <option>Brentwoof</option>
                            <option>More</option>
                            <option>More</option>
                          </select>
                        </div>
                        <div className="ltn__home-dealer-form-item ltn__custom-icon---- ltn__icon-meter---- col-lg-3 col-md-6">
                          <select className="nice-select">
                            <option>Property Status</option>
                            <option>Open house</option>
                            <option>Rent</option>
                            <option>Sale</option>
                            <option>Sold</option>
                          </select>
                        </div>
                        <div className="ltn__home-dealer-form-item ltn__custom-icon---- ltn__icon-calendar---- col-lg-3 col-md-6">
                          <select className="nice-select">
                            <option>Property Type</option>
                            <option>Apartment</option>
                            <option>Co-op</option>
                            <option>Condo</option>
                            <option>Single Family Home</option>
                          </select>
                        </div>
                        <div className="ltn__home-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-3 col-md-6">
                          <div className="btn-wrapper text-center mt-0 go-top">
                            {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                            <Link
                              to="/go-top"
                              className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            >
                              Search Properties
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchForm;
