import React, { Component } from "react";

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Testimonial
                </h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Andrew's knowledge of the market, attention to detail, and
                    personalized service made the entire process smooth and
                    stress-free. Not only was he able to find us the perfect
                    home, but he also navigated the negotiations and closing
                    process with expertise and efficiency. We couldn't be
                    happier with our new home, and we have Andrew to thank for
                    making it possible!
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Jake Armijo</h5>
                      <label>Home Buyer</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Thank you for your excellent work in helping us find and
                    purchase our new home. Your professionalism and expertise
                    made the process smooth and stress-free. We are very happy
                    with our new home and grateful for your help in making it
                    possible.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Kelian Anderson</h5>
                      <label>Home Buyer</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    We are sincerely gratitude for Andrew’s hard work and
                    dedication in selling our home. We were on a tight timeline
                    and he made it happen. He sold our home quickly and for a
                    great price. We couldn't be happier with the outcome and
                    have Andrew to thank for it.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>The Joseph's</h5>
                      <label>Home Sellers</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Working side by side with Andrew has been a fantastic
                    experience and I have learned so much from him. It was a
                    treat from the beginning, and it paid off in the end with a
                    smooth and successful sale. I couldn't have asked for a
                    better coworker to work with on this project.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Coworker</h5>
                      <label>Real Estate Agent</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
