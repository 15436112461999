import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
class BlogList extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="ltn__blog-area mb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 order-lg-2">
              <div className="ltn__blog-list-wrap">
                {/* Blog Item */}
                <div className="ltn__blog-item ltn__blog-item-5 go-top">
                  <div className="ltn__blog-img">
                    <Link to="/news-blog-details">
                      <img
                        src={
                          publicUrl +
                          "assets/img/blog/city/nashville-city-fireworks.jpg"
                        }
                        alt="Move to Nashville"
                      />
                    </Link>
                  </div>
                  <div className="ltn__blog-brief">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-category">
                          <Link to="/news-blog-details">Real Estate</Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="ltn__blog-title">
                      <Link to="/news-blog-details">
                        Why move to Nashville?
                      </Link>
                    </h3>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Dec 9, 2022
                        </li>
                      </ul>
                    </div>
                    <p>
                      Nashville, Tennessee is a vibrant and exciting city with a
                      lot to offer for people looking to move. Whether you are a
                      young professional looking for a great job opportunity, a
                      family looking for a great place to raise your children,
                      or a retiree looking to enjoy your golden years, Nashville
                      has something for everyone.
                    </p>
                    <div className="ltn__blog-meta-btn">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author">
                            <Link to="/news-blog">
                              <img
                                src={
                                  publicUrl +
                                  "assets/img/head-shots/selfie-1.jpg"
                                }
                                alt="#"
                              />
                              By: Andrew Phipps
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to="/news-blog-details">
                          <i className="fas fa-arrow-right" />
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Blog Item (Video) */}
                <div className="ltn__blog-item ltn__blog-item-5 ltn__blog-item-video">
                  <div className="ltn__video-img">
                    <img
                      src={
                        publicUrl +
                        "assets/img/blog/city/nashville-city-sunset.jpg"
                      }
                      alt="Nashville Weather"
                    />
                  </div>
                  <div className="ltn__blog-brief go-top">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-category">
                          <Link to="/news-blog-details-1">Real Estate</Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="ltn__blog-title">
                      <Link to="/news-blog-details-1">Weather in Nashville</Link>
                    </h3>
										<div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Nov 26, 2022
                        </li>
                      </ul>
                    </div>
                    <p>
                      Nashville has a varied and pleasant climate, making it a
                      great place to visit year-round. Whether you're looking to
                      enjoy the outdoors in the summer or experience the city's
                      music and culture in the cooler months, Nashville has
                      something for everyone.
                    </p>
                    <div className="ltn__blog-meta-btn">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author">
                            <Link to="/news-blog">
                              <img
                                src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                                alt="#"
                              />
                              By: Andrew Phipps
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to="/news-blog-details">
                          <i className="fas fa-arrow-right" />
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Blog Item (Gallery) */}
                <div className="ltn__blog-item ltn__blog-item-5 ltn__blog-item-gallery">
                  <div className="ltn__blog-gallery-active slick-arrow-1 slick-arrow-1-inner">
                    <div className="ltn__blog-gallery-item">
                      <Link to="/news-blog-details-2">
                        <img
                          src={
                            publicUrl +
                            "assets/img/blog/city/broadway-people-walking.jpg"
                          }
                          alt="Nashville Broadway"
                        />
                      </Link>
                    </div>
                    <div className="ltn__blog-gallery-item">
                      <Link to="/news-blog-details-2">
                        <img
                          src={publicUrl + "assets/img/blog/city/alexander-wark-RKBrJ1gveHo-unsplash.jpg"}
                          alt="Blog34"
                        />
                      </Link>
                    </div>
                    <div className="ltn__blog-gallery-item">
                      <Link to="/news-blog-details-2">
                        <img
                          src={publicUrl + "assets/img/blog/city/william-king-quChYudzB0-unsplash.jpg"}
                          alt="Blog31"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="ltn__blog-brief">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-category">
                          <Link to="/news-blog">Real Estate</Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="ltn__blog-title">
                      <Link to="/news-blog-details-2">Nashville Nightlife</Link>
                    </h3>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          Nov 28, 2022
                        </li>
                      </ul>
                    </div>
                    <p>
                      Nashville, also known as Music City, is famous for its
                      vibrant nightlife and live music scene. Whether you're a
                      fan of country, rock, blues, or any other genre, you'll
                      find something to enjoy in Nashville's bustling music
                      scene.
                    </p>
                    <div className="ltn__blog-meta-btn">
                      <div className="ltn__blog-meta">
                        <ul>
                          <li className="ltn__blog-author">
                            <Link to="/news-blog">
                              <img
                                src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                                alt="#"
                              />
                              By: Andrew Phipps
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__blog-btn">
                        <Link to="/news-blog-details">
                          <i className="fas fa-arrow-right" />
                          Read more
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Blog Item (Audio) */}
                {/* Blog Item (No Image) */}
                {/* Blog Item (Quote) */}
                {/* Blog Item (Background Image) */}
                {/*  */}
              </div>
              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="ltn__pagination-area text-center">
                    <div className="ltn__pagination">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-double-left" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">1</Link>
                        </li>
                        <li className="active">
                          <Link to="#">2</Link>
                        </li>
                        <li>
                          <Link to="#">3</Link>
                        </li>
                        <li>
                          <Link to="#">...</Link>
                        </li>
                        <li>
                          <Link to="#">10</Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fas fa-angle-double-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }
}

export default BlogList;
