import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        user_name: "",
        user_email: "",
        user_service: "",
        user_message: "",
        verified: false,
      },
    };
  }

  onChange(value) {
    console.log("Captcha value:", value);
    this.verified = true;
  }

  sendEmail = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_71t43vl",
        "ap_real_estate_1",
        this.form,
        "U7uObU66v96myis6v"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    this.form = {
      user_name: "",
      user_email: "",
      user_service: "",
      user_message: "",
      verified: false,
    };
  };
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div className="col-lg-4 go-top">
        <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
          {/* Author Widget */}
          <div className="widget ltn__author-widget">
            <div className="ltn__author-widget-inner text-center">
              <img
                src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                alt="Team"
              />
              <h5>Andrew Phipps</h5>
              <small>Real Estate Agent</small>
              {/* <div className="product-ratting">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fas fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-star" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fas fa-star-half-alt" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="far fa-star" />
                    </a>
                  </li>
                  <li className="review-total">
                    {" "}
                    <a href="#"> ( 1 Reviews )</a>
                  </li>
                </ul>
              </div> */}
              <p>
                Andrew Phipps is a real estate agent in Nashville, Tennessee,
                who brings a wealth of experience and expertise to the table.
                With over 10 years of experience in hospitality, banking, and
                technology, Andrew has honed his customer service, negotiation,
                and communication skills to provide exceptional service to his
                clients.
              </p>
              <div className="ltn__social-media">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/andrewjphipps"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/Phippsy806"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/andrew-phipps-6668131b5/"
                      title="LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Search Widget */}
          <div className="widget ltn__search-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Search Blog
            </h4>
            <form method="post" onSubmit={this.sendEmail}>
              <input
                type="text"
                name="search"
                placeholder="Feature Coming Soon!"
              />
              <button type="submit">
                <i className="fas fa-search" />
              </button>
            </form>
          </div>
          {/* Form Widget */}
          <div className="widget ltn__form-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Drop Me A Message
            </h4>
            <form action="#">
              <input type="text" name="user_name" placeholder="Your Name*" />
              <input type="text" name="user_email" placeholder="Your e-Mail*" />
              <input
                type="text"
                name="user_service"
                placeholder="Service Inquiry Type"
              />
              <textarea
                name="user_message"
                placeholder="Write Message..."
                defaultValue={""}
              />
              <ReCAPTCHA
                sitekey="6Leb8X4jAAAAAMv2QPeoGPo22ai79Bc_SquqsIF4"
                onChange={this.onChange}
              />
              <button
                type="submit"
                className="btn theme-btn-1"
                value="Send"
                disabled={!this.verified}
              >
                Send Message
              </button>
            </form>
          </div>
          {/* Top Rated Product Widget */}
          {/* <div className="widget ltn__top-rated-product-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Top Rated Product
            </h4>
            <ul>
              <li>
                <div className="top-rated-product-item clearfix go-top">
                  <div className="top-rated-product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product/1.png"}
                        alt="#"
                      />
                    </Link>
                  </div>
                  <div className="top-rated-product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h6>
                      <Link to="/product-details">
                        Luxury House In Greenville{" "}
                      </Link>
                    </h6>
                    <div className="product-price">
                      <span>$30,000.00</span>
                      <del>$35,000.00</del>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="top-rated-product-item clearfix">
                  <div className="top-rated-product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product/2.png"}
                        alt="#"
                      />
                    </Link>
                  </div>
                  <div className="top-rated-product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h6>
                      <Link to="/product-details">Apartment with Subunits</Link>
                    </h6>
                    <div className="product-price">
                      <span>$30,000.00</span>
                      <del>$35,000.00</del>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="top-rated-product-item clearfix">
                  <div className="top-rated-product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product/3.png"}
                        alt="#"
                      />
                    </Link>
                  </div>
                  <div className="top-rated-product-info">
                    <div className="product-ratting">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-star-half-alt" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="far fa-star" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <h6>
                      <Link to="/product-details">3 Rooms Manhattan</Link>
                    </h6>
                    <div className="product-price">
                      <span>$30,000.00</span>
                      <del>$35,000.00</del>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
          {/* Menu Widget (Category) */}
          {/* <div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Top Categories
            </h4>
            <ul>
              <li>
                <Link to="/news-blog">
                  Apartments <span>(26)</span>
                </Link>
              </li>
              <li>
                <Link to="/news-blog">
                  Picture Stodio <span>(30)</span>
                </Link>
              </li>
              <li>
                <Link to="/news-blog">
                  Office <span>(71)</span>
                </Link>
              </li>
              <li>
                <Link to="/news-blog">
                  Luxary Vilas <span>(56)</span>
                </Link>
              </li>
              <li>
                <Link to="/news-blog">
                  Duplex House <span>(60)</span>
                </Link>
              </li>
            </ul>
          </div> */}
          {/* Popular Product Widget */}
          {/* <div className="widget ltn__popular-product-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Popular Properties
            </h4>
            <div className="row ltn__popular-product-widget-active slick-arrow-1">
              <div className="col-12">
                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                  <div className="product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product-3/6.jpg"}
                        alt="#"
                      />
                    </Link>
                    <div className="real-estate-agent">
                      <div className="agent-img">
                        <Link to="/team-details">
                          <img
                            src={publicUrl + "assets/img/blog/author.jpg"}
                            alt="#"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="product-info">
                    <div className="product-price">
                      <span>
                        $349,00<label>/Month</label>
                      </span>
                    </div>
                    <h2 className="product-title">
                      <Link to="/product-details">New Apartment Nice View</Link>
                    </h2>
                    <div className="product-img-location">
                      <ul>
                        <li>
                          <Link to="/product-details">
                            <i className="flaticon-pin" /> Belmont Gardens,
                            Chicago
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                      <li>
                        <span>3 </span>
                        Bedrooms
                      </li>
                      <li>
                        <span>2 </span>
                        Bathrooms
                      </li>
                      <li>
                        <span>3450 </span>
                        square Ft
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                  <div className="product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product-3/4.jpg"}
                        alt="#"
                      />
                    </Link>
                    <div className="real-estate-agent">
                      <div className="agent-img">
                        <Link to="/team-details">
                          <img
                            src={publicUrl + "assets/img/blog/author.jpg"}
                            alt="#"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="product-info">
                    <div className="product-price">
                      <span>
                        $349,00<label>/Month</label>
                      </span>
                    </div>
                    <h2 className="product-title">
                      <Link to="/product-details">New Apartment Nice View</Link>
                    </h2>
                    <div className="product-img-location">
                      <ul>
                        <li>
                          <Link to="/product-details">
                            <i className="flaticon-pin" /> Belmont Gardens,
                            Chicago
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                      <li>
                        <span>3 </span>
                        Bedrooms
                      </li>
                      <li>
                        <span>2 </span>
                        Bathrooms
                      </li>
                      <li>
                        <span>3450 </span>
                        square Ft
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                  <div className="product-img">
                    <Link to="/product-details">
                      <img
                        src={publicUrl + "assets/img/product-3/5.jpg"}
                        alt="#"
                      />
                    </Link>
                    <div className="real-estate-agent">
                      <div className="agent-img">
                        <Link to="/team-details">
                          <img
                            src={publicUrl + "assets/img/blog/author.jpg"}
                            alt="#"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="product-info">
                    <div className="product-price">
                      <span>
                        $349,00<label>/Month</label>
                      </span>
                    </div>
                    <h2 className="product-title">
                      <Link to="/product-details">New Apartment Nice View</Link>
                    </h2>
                    <div className="product-img-location">
                      <ul>
                        <li>
                          <Link to="/product-details">
                            <i className="flaticon-pin" /> Belmont Gardens,
                            Chicago
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                      <li>
                        <span>3 </span>
                        Bedrooms
                      </li>
                      <li>
                        <span>2 </span>
                        Bathrooms
                      </li>
                      <li>
                        <span>3450 </span>
                        square Ft
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Popular Post Widget */}
          {/* Popular Post Widget (Twitter Post) */}
          {/* <div className="widget ltn__popular-post-widget ltn__twitter-post-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Twitter Feeds
            </h4>
            <ul>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/blog-details">
                      <i className="fab fa-twitter" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Carsafe - #Gutenberg ready @wordpress Theme for Car
                      Service, Auto Parts, Car Dealer available on @website
                      <a href="https://website.net">https://website.net</a>
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            June 22, 2020
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <a href="blog-details.html">
                      <i className="fab fa-twitter" />
                    </a>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Carsafe - #Gutenberg ready @wordpress Theme for Car
                      Service, Auto Parts, Car Dealer available on @website
                      <a href="https://website.net">https://website.net</a>
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            June 22, 2020
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="popular-post-widget-item clearfix">
                  <div className="popular-post-widget-img">
                    <Link to="/blog-details">
                      <i className="fab fa-twitter" />
                    </Link>
                  </div>
                  <div className="popular-post-widget-brief">
                    <p>
                      Carsafe - #Gutenberg ready @wordpress Theme for Car
                      Service, Auto Parts, Car Dealer available on @website
                      <a href="https://website.net">https://website.net</a>
                    </p>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <Link to="#">
                            <i className="far fa-calendar-alt" />
                            Oct 31, 2022
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
          {/* Social Media Widget */}
          <div className="widget ltn__social-media-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Follow Me
            </h4>
            <div className="ltn__social-media-2">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/andrewjphipps"
                    title="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Phippsy806"
                    title="Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/phippsy_1989/"
                    title="Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/andrew-phipps-6668131b5/"
                    title="LinkedIn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Tagcloud Widget */}
          {/* <div className="widget ltn__tagcloud-widget go-top">
            <h4 className="ltn__widget-title ltn__widget-title-border-2">
              Popular Tags
            </h4>
            <ul>
              <li>
                <Link to="/news-blog">Popular</Link>
              </li>
              <li>
                <Link to="/news-blog">desgin</Link>
              </li>
              <li>
                <Link to="/news-blog">ux</Link>
              </li>
              <li>
                <Link to="/news-blog">usability</Link>
              </li>
              <li>
                <Link to="/news-blog">develop</Link>
              </li>
              <li>
                <Link to="/news-blog">icon</Link>
              </li>
              <li>
                <Link to="/news-blog">Car</Link>
              </li>
              <li>
                <Link to="/news-blog">Service</Link>
              </li>
              <li>
                <Link to="/news-blog">Repairs</Link>
              </li>
              <li>
                <Link to="/news-blog">Auto Parts</Link>
              </li>
              <li>
                <Link to="/news-blog">Oil</Link>
              </li>
              <li>
                <Link to="/news-blog">Dealer</Link>
              </li>
              <li>
                <Link to="/news-blog">Oil Change</Link>
              </li>
              <li>
                <Link to="/news-blog">Body Color</Link>
              </li>
            </ul>
          </div> */}
          {/* Banner Widget */}
          <div className="widget ltn__banner-widget d-none go-top">
            <li>
              <a
                href="https://aphipps615.kw.com/search/location/ChIJPZDrEzLsZIgRoNrpodC5P30-0.1697115188348821,nashville,Nashville%2C%20TN%2C%20USA?fallBackCityAndState=Nashville%2C%20TN&fallBackPosition=36.1626638%2C%20-86.7816016&fallBackStreet=&isFallback=true&viewport=36.4054959%2C-86.5155879%2C35.9677851%2C-87.054903"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
              </a>
            </li>
          </div>
        </aside>
      </div>
    );
  }
}

export default Sidebar;
