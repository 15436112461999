import React, { Component } from "react";

class VideoV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__video-popup-area ltn__video-popup-margin---">
        <div
          className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1"
          data-bs-bg={publicUrl + "assets/img/head-shots/selfie-3.jpg"}
        >
          <a
            className="ltn__video-icon-2 ltn__video-icon-2-border---"
            href="https://www.youtube.com/embed/kyexru3QYY4"
            data-rel="lightcase:myCollection"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-play" />
          </a>
        </div>
      </div>
    );
  }
}

export default VideoV1;


