import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const form = useRef();
  const [verified, setVerified] = useState(false);

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_71t43vl",
        "ap_real_estate_1",
        form.current,
        "U7uObU66v96myis6v"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Let's Find Your Dream Home</h4>
              <form
                id="contact-form"
                method="post"
                ref={form}
                onSubmit={sendEmail}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="user_name"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="user_email"
                        placeholder="Enter email address"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select className="nice-select" name="user_service">
                        <option>Select Service Type</option>
                        <option>Home Buying</option>
                        <option>Home Selling</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="user_phone"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="user_message"
                    placeholder="Enter message"
                    defaultValue={""}
                  />
                </div>
                <div className="btn-wrapper mt-0">
                  <ReCAPTCHA
                    sitekey="6Leb8X4jAAAAAMv2QPeoGPo22ai79Bc_SquqsIF4"
                    onChange={onChange}
                  />
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    value="Send"
                    disabled={!verified}
                  >
                    Submit
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
