import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import ScrollToTop from "./components/scrollToTop";
import HomeV3 from "./components/home-v3";

import About from "./components/about";

import BlogMain from "./components/blog";
import BlogDetailsPage from "./components/blog-details-main";
import BlogDetailsMainOne from "./components/blog-details-main-1";
import BlogDetailsMainTwo from "./components/blog-details-main-2";


import Contact from "./components/contact";
import FaqV1 from "./components/faq";

import withTracker from "./components/withTracker";



const Root = () => {
    return (
      <HashRouter basename="/">
        <ScrollToTop/>
        <div>
          <Switch>
            <Route exact path="/" component={withTracker(HomeV3)} />
            <Route path="/about" component={withTracker(About)} />
            <Route path="/news-blog" component={withTracker(BlogMain)} />
            <Route path="/news-blog-details" component={withTracker(BlogDetailsPage)} />
            <Route path="/news-blog-details-1" component={withTracker(BlogDetailsMainOne)} />
            <Route path="/news-blog-details-2" component={withTracker(BlogDetailsMainTwo)} />
            <Route path="/contact" component={withTracker(Contact)} />
            <Route path="/faq" component={withTracker(FaqV1)} />
          </Switch>
        </div>
      </HashRouter>
    )
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
