import React from "react";
import NavbarV3 from "./global-components/navbar-v3";
import PageHeader from "./global-components/page-header";
import BlogLeftSidebar from "./blog-components/blog-left-sidebar";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const BlogMain = () => {
  return (
    <div>
      <NavbarV3 />
      <PageHeader headertitle="News & Blog" subheader="News & Blog" />
      <BlogLeftSidebar />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default BlogMain;
