import React, { Component } from "react";
import { Link } from "react-router-dom";

class FaqV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__faq-area mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-1"
                      aria-expanded="false"
                    >
                      Q: What areas of Nashville do you specialize in?
                    </h6>
                    <div
                      id="faq-item-2-1"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          A: As a Nashville-based real estate agent, I
                          specialize in the greater Nashville area, including
                          but not limited to neighborhoods such as downtown,
                          East Nashville, 12 South, Germantown, and Green Hills.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-2"
                      aria-expanded="true"
                    >
                      Q: How does the home-buying process work?
                    </h6>
                    <div
                      id="faq-item-2-2"
                      className="collapse show"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <div className="ltn__video-img alignleft">
                          <img
                            src={publicUrl + "assets/img/KWLogo.png"}
                            alt="Keller Williams Logo"
                          />
                          <a
                            className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----"
                            href="https://www.youtube.com/embed/D-mB1CjhNy4"
                            data-rel="lightcase:myCollection"
                          >
                            <i className="fa fa-play" />
                          </a>
                        </div>
                        <p>
                          A: The home-buying process can vary, but generally it
                          includes the following steps:
                          <ol>
                            <li>Pre-approval for a mortgage</li>
                            <li>
                              Finding a home that meets your needs and budget
                            </li>
                            <li>
                              Making an offer and negotiating the purchase price
                            </li>
                            <li>Completing a home inspection and appraisal</li>
                            <li>
                              Finalizing financing and closing on the sale.
                            </li>
                            <li>Enjoying your new home</li>
                          </ol>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-3"
                      aria-expanded="false"
                    >
                      Q: How do you determine the listing price for a home?
                    </h6>
                    <div
                      id="faq-item-2-3"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          A: Determining the listing price for a home involves
                          analyzing recent comparable sales in the area,
                          considering the home's unique features and condition,
                          and considering current market conditions.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-4"
                      aria-expanded="false"
                    >
                      Q: What sets you apart from other real estate agents in
                      Nashville?
                    </h6>
                    <div
                      id="faq-item-2-4"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          A: As a dedicated and experienced agent, I pride
                          myself on my in-depth knowledge of the Nashville real
                          estate market, my strong communication skills, and my
                          commitment to providing personalized service to each
                          of my clients.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* card */}
                  <div className="card">
                    <h6
                      className="collapsed ltn__card-title"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-item-2-5"
                      aria-expanded="false"
                    >
                      Q: How do I get started in finding my dream home in
                      Nashville?
                    </h6>
                    <div
                      id="faq-item-2-5"
                      className="collapse"
                      data-bs-parent="#accordion_2"
                    >
                      <div className="card-body">
                        <p>
                          A: The first step in finding your dream home in
                          Nashville is to contact me and discuss your needs,
                          preferences, and budget. From there, I will work with
                          you to create a personalized home search strategy and
                          keep you updated on new listings that match your
                          criteria.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="need-support text-center mt-100">
                  <h2>Still need help? Reach out to support 24/7:</h2>
                  <div className="btn-wrapper mb-30 go-top">
                    <Link to="/contact" className="theme-btn-1 btn">
                      Contact Me
                    </Link>
                  </div>
                  <h3>
                    <i className="fas fa-phone" /> 217-549-6773
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area ltn__right-sidebar">
                {/* Newsletter Widget */}
                <div className="widget ltn__search-widget ltn__newsletter-widget">
                  <h6 className="ltn__widget-sub-title">
                    {'//'} subscribe - COMING SOON
                  </h6>
                  <h4 className="ltn__widget-title">Get Newsletter</h4>
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Sign Up"
                      disabled
                    />
                    <button type="submit" disabled>
                      <i className="fas fa-search" />
                    </button>
                  </form>
                  <div className="ltn__newsletter-bg-icon">
                    <i className="fas fa-envelope-open-text" />
                  </div>
                </div>
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget go-top">
                  <Link to="/about">
                    <img
                      src={publicUrl + "assets/img/head-shots/selfie-1.jpg"}
                      alt="Selfie"
                    />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqV1;
